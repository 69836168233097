import { useContext, useState } from 'react';
import { ImArrowDown2 } from 'react-icons/im';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { ContactGroup, useContactGroups } from '../../api/contactGroups';
import { Contact, isContact, useContacts } from '../../api/contacts';
import { Product } from '../../api/products';
import { getTransactionsFromSlot, useSlot } from '../../api/slots';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import { NotificationsConfigProfile, Transaction, TransactionStatus, TransactionType } from '../../common/transactions';
import ActionCard, { isAction } from '../../components/domain/ActionCard';
import { ActionCardElement } from '../../components/elements/ActionCardElement';
import { LoginEvent } from '../../events/LoginEvent';
import { LookupType } from '../../hooks/lookup/usePerformLookup';
import { useAppDispatch } from '../../hooks/redux';
import useActivity from '../../hooks/useActivity';
import useTransactionsWithSlots from '../../hooks/useTransactionsWithSlots';
import { changeAllowRedirect, home } from '../../redux/ViewSlice';
import { filterContactsByContactGroupId } from '../../services/contact/ContactFilter';
import { isDelivery } from '../../services/contact/GlobalPermissions';
import { filterContactGroupsByContact, getOnlySharingGroups } from '../../services/contactGroup/ContactGroupFilter';
import { preferNonStockSlot } from '../../services/slot/SlotFilter';
import { filterTransactionsByReceiver, filterTransactionsByStatus, getTransactionsFromContactGroups } from '../../services/transaction/TransactionFilters';
import LockoutView from '../../views/LockoutView';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';
import ErrorView from '../../views/common/ErrorView';
import LoadingView from '../../views/common/LoadingView';
import ProductAuthRequiredView from '../../views/nike/ProductAuthRequiredView';
import AuthenticatedContactWorkflow from '../AuthenticatedContact/AuthenticatedContactWorkflow';
import JustDrop from '../JustDrop';
import Pickup from '../Pickup';
import ScannerLookupModal, { ScannerLookupCallbackResult, ScannerLookupType } from '../lookup/ScannerLookupModal';
import HowDoesItWorkWorkflow from '../nike/HowDoesItWorkWorkflow';
import { isNikeDeliveryPerson } from '../nike/permissions';
import BadgeRegistrationWorkflow from '../nike/registration/BadgeRegistrationWorkflow';

enum PuDoViews {
    HOME = 'home',
    DROPOFF = 'dropoff',
    PICKUP = 'pickup',
    BADGE_REGISTRATION = 'badge_registration',

    LOGIN_NOT_ALLOWED = 'login_not_allowed',
    PRODUCT_AUTH_REQUIRED = 'product_auth_required',
    PRODUCT_DROPOFF = 'product_dropoff',
    HOW_DOES_IT_WORK = 'how_does_it_work'
}

const MainWorkflow = () => {
    //external hooks
    const [, newActivity] = useActivity();

    //necesary data
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: transactionSlotMap } = useTransactionsWithSlots({ spotId: spot?.id });
    const { data: contactGroups } = useContactGroups(/*null, undefined, {enabled: !spot || spot.account !== 15}*/); //imec id=21 for test, imec id=15 for production
    const { data: contacts } = useContacts(undefined, { accountId: spot?.account });
    const dispatch = useAppDispatch();

    //navigation
    const [currentView, changeCurrentView] = useState<PuDoViews | ActionCard>(PuDoViews.HOME);
    const [customView, changeCustomView] = useState<ActionCard | undefined>();
    const [showQrHelp, changeShowQrHelp] = useState(false);

    //lookup
    const [doLookupReset, changeDoLookupReset] = useState<boolean>(true);
    const [currentContact, changeCurrentContact] = useState<Contact | undefined>(undefined);
    const [badgeRegistrationValue, changeBadgeRegistrationValue] = useState<undefined | string>(undefined);
    const [productToDropoff, changeProductToDropoff] = useState<Product | null>(null);
    const [currentTransaction, changeCurrentTransaction] = useState<Transaction | undefined>(undefined);
    const { data: currentTransactionSlot } = useSlot(currentTransaction?.slot_id);
    const [lookupEnabled, changeLookupEnabled] = useState<boolean>(true);

    const onHome = () => {
        newActivity();
        changeLookupEnabled(true);
        changeDoLookupReset(!doLookupReset);
        changeCustomView(undefined);
        changeCurrentTransaction(undefined);
        changeBadgeRegistrationValue(undefined);
        changeProductToDropoff(null);
        changeCurrentView(PuDoViews.HOME);
        dispatch(home());
    };
    const onLogout = () => {
        changeCurrentContact(undefined);
        onHome();
    };
    const loadView = (view: PuDoViews | ActionCard) => {
        newActivity();
        if (isAction(view)) {
            if (view.actionText.includes('Scan') || view.actionText.includes('QR') || view.actionText.includes('NFC')) {
                changeShowQrHelp(true);
                setTimeout(function () {
                    changeShowQrHelp(false);
                }, 3000);
            } else changeCustomView(view);
        } else {
            changeCustomView(undefined);
            changeCurrentView(view);
        }
    };

    const lookupCallback = (result: ScannerLookupCallbackResult) => {
        if (result.contact) {
            if (isContact(result.contact)) {
                changeCurrentContact(result.contact);
                new LoginEvent(result.contact).dispatch();
                dispatch(changeAllowRedirect({ views: [], value: true }));
                loadView(PuDoViews.HOME);
            }
        } else if (result.transaction) {
            changeCurrentTransaction(result.transaction);
            if (result.transactionType === LookupType.PICKUP) {
                loadView(PuDoViews.PICKUP);
            } else if (result.transactionType === LookupType.DROPOFF) {
                loadView(PuDoViews.DROPOFF);
            }
        } else if (result.product) {
            changeProductToDropoff(result.product);
            if (currentContact && (isDelivery(currentContact) || isNikeDeliveryPerson(currentContact))) {
                loadView(PuDoViews.PRODUCT_DROPOFF);
            } else loadView(PuDoViews.PRODUCT_AUTH_REQUIRED);
        } else if (terminal?.settings_contact_registration_allowed && result.currentLookup && result.currentLookup != '63 00') {
            changeBadgeRegistrationValue(result.currentLookup);
            loadView(PuDoViews.BADGE_REGISTRATION);
            changeDoLookupReset(!doLookupReset);
        }
    };

    //TODO: put inside hook maybe
    const multipleTransactions = () => {
        let transactions: Transaction[] = [];
        if (
            currentTransaction!.type === TransactionType.PUDO &&
            currentTransactionSlot!.settings_allow_multiple_pudo_pickup_transactions &&
            transactionSlotMap
        ) {
            transactions = filterTransactionsByStatus(getTransactionsFromSlot(transactionSlotMap, currentTransactionSlot!), TransactionStatus.READY_FOR_PICKUP);
            if (currentTransaction!.receiver) {
                const groups = getOnlySharingGroups(filterContactGroupsByContact(contactGroups!, currentTransaction!.receiver as Contact), spot!);
                transactions = getTransactionsFromContactGroups(transactions, groups, contacts!);
            } else if (currentTransaction!.receiver_group) {
                if (spot!.sharing_allowed_for_contact_groups.includes(currentTransaction!.receiver_group.id)) {
                    const receivers = filterContactsByContactGroupId(contacts!, currentTransaction!.receiver_group.id);
                    receivers.forEach((receiver) => {
                        transactions.push(...filterTransactionsByReceiver(transactions, receiver.id));
                    });
                }
            }
        }
        return transactions;
    };

    let content;
    if (customView) {
        content = customView.view;
    } else if (!currentContact && currentView === PuDoViews.BADGE_REGISTRATION && badgeRegistrationValue) {
        content = (
            <BadgeRegistrationWorkflow
                badgeValue={badgeRegistrationValue}
                onInactivity={onHome}
                onHome={onHome}
            />
        );
    } else if (currentView === PuDoViews.HOME) {
        content = (
            <AuthenticatedContactWorkflow
                onHome={onHome}
                onInactivity={onLogout}
                contact={currentContact}
                onSelectAction={(action) => loadView(action)}
                onHowDoesItWork={() => loadView(PuDoViews.HOW_DOES_IT_WORK)}
                workflow={terminal?.workflow}
                defaultLookup={changeLookupEnabled}
                authenticateContact={(contact) => {
                    changeCurrentContact(contact);
                    dispatch(changeAllowRedirect({ views: [], value: true }));
                    loadView(PuDoViews.HOME);
                }}
            />
        );
    } else if (currentView === PuDoViews.LOGIN_NOT_ALLOWED) {
        const homeNav = <BackAndHomeNavigationButtons onHome={onLogout} />;
        content = (
            <ErrorView
                navbarItems={homeNav}
                onInactivity={onLogout}
                title={
                    <FormattedMessage
                        id='workflow.nike.NikeWorkflow.loginNotAllowedTitle'
                        description='The title on the error screen if a user is not allowed to login'
                        defaultMessage='Account disabled'
                    />
                }
                message={
                    <FormattedMessage
                        id='workflow.nike.NikeWorkflow.loginNotAllowedMessage'
                        description='The message on the error screen if a user is not allowed to login'
                        defaultMessage='Your account has not been enabled to use this DistriSPOT. Contact your coach if you think this is a mistake.'
                    />
                }
            />
        );
    } else if (currentView === PuDoViews.DROPOFF) {
        if (currentTransactionSlot === undefined) {
            <LoadingView
                onHome={onHome}
                onInactivity={onLogout}
            />;
        } else {
            content = (
                <JustDrop
                    onInactivity={onLogout}
                    onHome={onHome}
                    onBack={onHome}
                    receiverData={
                        currentTransaction
                            ? {
                                  contact: currentTransaction?.sender as Contact,
                                  contactGroup: currentTransaction.sender_group as ContactGroup
                              }
                            : undefined
                    }
                    senderData={
                        currentTransaction
                            ? {
                                  senderContact: currentTransaction.receiver as Contact,
                                  sender: currentTransaction.receiver_group as ContactGroup
                              }
                            : undefined
                    }
                    slot={currentTransactionSlot}
                    transaction={currentTransaction}
                />
            );
        }
    } else if (currentView === PuDoViews.PICKUP) {
        if (currentTransaction && currentTransactionSlot) {
            const transactions = multipleTransactions();
            content = (
                <Pickup
                    onInactivity={onLogout}
                    onHome={onHome}
                    transactions={transactions.length > 1 ? transactions : undefined}
                    transaction={!(transactions.length > 1) ? currentTransaction : undefined}
                    slot={currentTransactionSlot}
                />
            );
        } else {
            <LoadingView
                onHome={onHome}
                onInactivity={onLogout}
            />;
        }
    } else if (currentView === PuDoViews.HOW_DOES_IT_WORK) {
        content = (
            <HowDoesItWorkWorkflow
                onInactivity={onLogout}
                onLogout={onLogout}
                onDone={onLogout}
            />
        );
    } else if (currentView === PuDoViews.PRODUCT_AUTH_REQUIRED || !currentContact) {
        content = (
            <ProductAuthRequiredView
                onHome={onLogout}
                onInactivity={onLogout}
            />
        );
    } else if (currentView === PuDoViews.PRODUCT_DROPOFF) {
        //VENDING transaction dropoff (should always be delivery)
        content = (
            <JustDrop
                products={[productToDropoff!]}
                senderData={{ senderContact: currentContact }}
                receiverData={{}}
                contact={currentContact}
                onHome={onHome}
                onInactivity={onLogout}
                autoAssignSlot={true}
                autoAssignFilter={preferNonStockSlot}
                notificationsConfig={{ profile: NotificationsConfigProfile.NO_NOTIFICATIONS }}
                transactionType={TransactionType.VENDING}
            />
        );
    } else {
        changeCurrentView(PuDoViews.HOME);
        content = (
            <BaseView>
                <h3 className='mt-4 text-center'>Page not found. Redirecting to home.</h3>
            </BaseView>
        );
    }
    return (
        <LockoutView>
            {content}
            {currentView === PuDoViews.HOME && lookupEnabled && (
                <ScannerLookupModal
                    scannerLookupTypes={[ScannerLookupType.TRANSACTION, ScannerLookupType.CONTACT, ScannerLookupType.PRODUCT]}
                    enabled={currentView === PuDoViews.HOME && lookupEnabled}
                    resultCallback={lookupCallback}
                    doReset={doLookupReset}
                />
            )}

            {showQrHelp ? (
                <div className='scanner-help-message-backdrop'>
                    <div className='scanner-help-message mb-3'>
                        <ActionCardElement
                            description={
                                <FormattedMessage
                                    id='views.HomeView.scanHereMessage'
                                    description='The text show on the home screen when the user wants to use the scanner'
                                    defaultMessage='Use the scanner below'
                                />
                            }
                            fullWidth={true}
                            highlight
                            color='success'>
                            <ImArrowDown2
                                size={265}
                                className='scanner-help-message-icon'
                            />
                        </ActionCardElement>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </LockoutView>
    );
};

export default MainWorkflow;
